import "./about-blacklist.scss";
import React from "react";
import ReactMarkdown from "react-markdown";
import breaks from "remark-breaks";
import CirclePlus from "@components/circle-plus";

import {
	Accordion,
	AccordionItem,
	AccordionItemHeading,
	AccordionItemButton,
	AccordionItemPanel
} from "react-accessible-accordion";

const AboutBL = () => (
	<>
		<section className="full-width about-us">
			<div className="grid-container">
				<div className="row">
					<div className="col-xs-12 col-md-offset-2 col-md-8 col-sm-offset-1 col-sm-10">
						<h2 className="about-us__header">О задолжности в транспорте</h2>
					</div>

					<div className="col-xs-12 col-md-offset-3 col-md-6 col-sm-offset-1 col-sm-10">
						<p className="about-us__text">
						В настоящее время в г.Казань в части подвижного состава оплата проезда реализована в режиме Офлайн.       
						Это режим оплаты с отложенным формированием банковских транзакций. Вы получаете билет,а уже после происходит списание</p>
						<p className="about-us__text">
							— Это режим оплаты с отложенным формированием транзакций<br></br>
							— Вы получаете билет, уже после происходит списание
						</p>
						<h4 className="about-us__title">Почему мы используем Офлайн режим?</h4>

						<p className="about-us__text">
							— Успешная оплата при отсутствии связи с банком<br></br>
							— Высокая скорость оплаты банковскими картами
						</p>

						<h4 className="about-us__title">Посмотреть историю поездок по банковской карте и погасить задолженность:</h4>
						
						<a className="btn-check-balance" rel="noopener noreferrer" href="https://transkarta.uniteller.ru/auth/login" target="_blank" title="Личный кабинет">Личный кабинет</a>
					</div>


					<div className="col-xs-12 col-md-offset-2 col-md-8 col-sm-offset-1 col-sm-10">
						<Accordion
							allowZeroExpanded
							allowMultipleExpanded
							className="accordion accordion_theme_faq"
						>
									<AccordionItem key="1">
										<AccordionItemHeading>
											<AccordionItemButton>
												<div className="container">
													<CirclePlus className="circle-plus" />
													<span>Почему банковская карта находится в черном списке?</span>
												</div>
											</AccordionItemButton>
										</AccordionItemHeading>
										<AccordionItemPanel>
												<div className="markdown-faq">
													<ReactMarkdown source={"Ранее вы совершили поездку (и получили билет) по банковской карте с оплатой в офлайн режиме, однако денежные средства по каким-либо причинам списаны не были, в связи с чем образовалась задолженность и карта попала в чёрный список. Погасить задолженность необходимо самостоятельно на сайте Транспортной карты в разделе: карты – личный кабинет банковской карты."} plugins={[breaks]} />
												</div>	
										</AccordionItemPanel>
									</AccordionItem>
									<AccordionItem key="2">
										<AccordionItemHeading>
											<AccordionItemButton>
												<div className="container">
													<CirclePlus className="circle-plus" />
													<span>Почему банковская карта попадает в локальный стоп-лист?</span>
												</div>
											</AccordionItemButton>
										</AccordionItemHeading>
										<AccordionItemPanel>
												<div className="markdown-faq">
													<ReactMarkdown source={"Вы совершаете поездку по банковской карте с оплатой в Офлайн режиме (получаете билет), но пока банк не списал денежные средства за совершённую поездку ваша карта будет находиться в локальном стоп-листе. Важно понимать, что карта будет находиться в локальном стоп-листе только для того терминала, через который была совершена оплата проезда."} plugins={[breaks]} />
												</div>	
										</AccordionItemPanel>
									</AccordionItem>
								
						</Accordion>
					</div>




					
				</div>
			</div>

		</section>
	</>
);

export default AboutBL;
